import { endpoints } from '@admin/api/endpoints/other-transaction-expenditure.js';
import { rupiahFormatter } from 'Utils/formatter';
import { wait } from 'Utils/etc';

const ACCEPTED_MAX_FILE_SIZE = 8;
const ACCEPTED_FILE_TYPE = 'image/jpg,image/jpeg,image/png,application/pdf';

export default {
  data() {
    return {
      showConfirmationModal: false,

      display: {
        property: '',
        city: '-',
        remainingContract: '-',
        bank_name: '-',
        account_number: '-',
        account_name: '-',
        total_expense: null,
      },

      input: {
        cash_out_id: null,
        property_id: '',
        expense_detail: [],
        total_expense: 0,
        attachment_id: null,
        invoice_number: null,
        vendor_id: null,
      },

      options: {
        cash_out: [],
        expense_category: [],
        product_type: [],
        propertyName: [],
        vendors: [],
      },

      isLoading: {
        properties: false,
        options: false,
        propertyList: false,
        postData: false,
        updateData: false,
        page: false,
      },

      isValid: {
        expenses: false,
      },

      fetchGetPropertiesTimeout: null,

      attachment: {
        name: null,
        url: null,
        size: ACCEPTED_MAX_FILE_SIZE,
        type: ACCEPTED_FILE_TYPE,
        description: `Masukkan file invoice/nota berupa .pdf/.jpg/.jpeg/.png dengan ukuran maksimal ${ACCEPTED_MAX_FILE_SIZE} MB.`,
        progress: 0,
        isError: false,
        isEmpty: true,
        message: null,
        isDeleting: false,
        deleted: false,
        errMessage: null,
        oldValue: null,
        hasSoftDeleted: false,
      },

      presetExpense: [],
    };
  },

  computed: {
    formattedTotalExpense() {
      const value = this.input.total_expense
        ? rupiahFormatter(this.input.total_expense)
        : '-';
      return value || '-';
    },

    isAllFieldFilled() {
      let mandatoryField = null;

      /* eslint-disable no-unused-vars */
      if (this.input.cash_out_id === 1) {
        const {
          invoice_number,
          expense_detail,
          vendor_id,
          ...rest
        } = this.input;
        mandatoryField = rest;
      } else {
        const { invoice_number, expense_detail, ...rest } = this.input;
        mandatoryField = rest;
      }

      const isAllFilled = !Object.values(mandatoryField).some(
        value => value === null || value === ''
      );

      return isAllFilled && this.isValid.expenses;
    },
  },

  watch: {
    'input.expense_detail': {
      deep: true,
      handler() {
        if (this.input.expense_detail.length) {
          const total = this.input.expense_detail.reduce(
            (prevValue, nextItem) => prevValue + nextItem.amount,
            0
          );

          this.input.total_expense = total;
        }
      },
    },

    'input.vendor_id': {
      deep: true,
      handler(value) {
        const selectedVendor = this.options.vendors.find(
          item => item.id === value
        );

        this.display = {
          ...this.display,
          bank_name: selectedVendor?.bank_name,
          account_number: selectedVendor?.account_number,
          account_name: selectedVendor?.account_name,
        };
      },
    },
  },

  methods: {
    async fetchGetPropertyExpensesOptions() {
      this.isLoading.options = true;
      const response = await endpoints.getPropertyExpensesOptions();

      if (response) {
        Object.keys(this.options).forEach(key => {
          if (key !== 'propertyName' && key !== 'vendors') {
            this.options[key] = response[key].map(item => ({
              ...item,
              val: item.id,
            }));
          }
        });
      }
      this.isLoading.options = false;
    },

    async fetchGetPropertyExpensesVendors() {
      this.isLoading.options = true;
      const response = await endpoints.getPropertyExpensesVendors();

      if (response) {
        const formattedArray = [...response.external, ...response.internal].map(
          item => ({
            ...item,
            val: item.id,
            label: `${item.label} (${
              item.type === 'external' ? 'Vendor' : 'Internal Mamikos'
            })`,
          })
        );

        this.options.vendors = formattedArray;
      }
      this.isLoading.options = false;
    },

    async fetchGetProperties(params) {
      this.isLoading.propertyList = true;
      const response = await endpoints.getProperties(params);

      if (response.properties) {
        this.options.propertyName = response.properties.map(item => ({
          val: item.id,
          label: item.name,
          remainingContract: item.remaining_contract,
          city: item.city,
        }));
      }
      this.isLoading.propertyList = false;
    },

    async fetchPostAttachment(data) {
      this.attachment.progress = 65;

      const response = await endpoints.postExpenditureAttachment(data);

      if (response.id) {
        this.input.attachment_id = response.id;
        this.attachment.isEmpty = false;
      } else {
        this.attachment.isError = true;
        this.attachment.message = 'Failed';
      }

      this.attachment.progress = 100;
    },

    handleDeleteAttachment() {
      this.fetchDeleteAttachment(this.input.attachment_id);
    },

    async fetchDeleteAttachment(attachmentId) {
      this.attachment.isError = false;
      this.attachment.isDeleting = true;
      this.attachment.message = 'Deleting...';
      this.attachment.url = null;

      if (attachmentId) {
        const response = await endpoints.deleteExpenditureAttachment(
          attachmentId
        );

        if (response) {
          this.attachment.message = null;
          this.attachment.isEmpty = true;
          this.attachment.oldValue = null;
          this.attachment.hasSoftDeleted = false;
          this.attachment.deleted = true;
          this.input.attachment_id = null;
        } else {
          this.attachment.message = 'Failed';
        }
      } else {
        await wait(1500);

        this.attachment.message = null;
        this.attachment.deleted = true;
        this.attachment.isEmpty = true;
      }

      this.attachment.isDeleting = false;
    },

    async onSearchInputProperty(payload) {
      this.isLoading.propertyList = true;
      if (!payload) {
        this.display.city = '-';
        this.display.remainingContract = '-';
      }

      const params = {
        name: payload,
      };

      clearTimeout(this.fetchGetPropertiesTimeout);
      this.fetchGetPropertiesTimeout = setTimeout(() => {
        this.fetchGetPropertiesTimeout = null;
        this.fetchGetProperties(params);
      }, 1000);
    },

    onSelectProperty(payload) {
      const { label, val } = payload;
      if (label || val) {
        this.display.property = label;
        this.input.property_id = val;
      }

      this.display.city = payload.city;
      this.display.remainingContract = `${payload.remainingContract} Bulan`;
    },

    async handleChangeFile(file) {
      const mime = this.attachment.type.split(',');
      const mbInBytes = 1042157;
      this.attachment.name = file.name;
      this.attachment.isEmpty = false;

      const isSizeValid = file.size < this.attachment.size * mbInBytes;
      const isTypeValid = mime.includes(file.type);

      if (!isSizeValid || !isTypeValid) {
        this.attachment.isError = true;
        this.attachment.errMessage =
          'File harus berupa .pdf/.jpg/.jpeg/.png dengan ukuran maksimal 8 MB.';
        this.attachment.message = 'Failed';
      } else {
        this.attachment.isError = false;
        this.attachment.errMessage = null;
        this.attachment.progress = 30;

        const fileDetail = new FormData();
        fileDetail.append('file', file);

        this.fetchPostAttachment(fileDetail);
      }
    },

    expensesValidation(payload) {
      this.isValid.expenses = payload;
    },

    getExpenses(payload) {
      this.input.expense_detail = payload;
    },
  },
};
